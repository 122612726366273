import React from 'react'
import SEO from '../components/Blocks/SEO'
import Home from '../components/Pages/Home'

function HomePage() {
  return (
    <React.Fragment>
      <SEO title="F1 och annan motorsport – Bästa motorsportsidan online (2020)" />
      <Home />
    </React.Fragment>
  )
}

export default HomePage
