import React from 'react'
import { Link } from 'gatsby'
import styles from './Home.module.css'
import Page from '../Layout/Page'

function Home(props) {
  return (
    <Page>
      <section className={styles.hero}>
        <div className={styles.content}>
          <div className={styles.text}>
            <h1 className={styles.heading}>
              <strong>F1 och annan motorsport</strong>, nyheter och info 2020.
            </h1>
            <p className={styles.subheading}>
              Här finns allt för ett äkta motorsportfan. Statisk och analyser om
              förare, team, tävlingar, tv-sändningar, speltips m.m 🏎
            </p>
          </div>
        </div>
      </section>
      <section className={styles.intro}>
        <div className={styles.content}>
          <h2 className={styles.borderHeading}>Det här är motorsportfans.se</h2>
          <p>
            Motorpsortfans.se är en sida av fans, för fans. Det är den naturliga
            samlingsplatsen online för alla som älskar motorsport. Vår tanke med
            sidan är att du som besökare alltid ska ha någonstans att vända dig
            när du till exempel vill veta mer om ett specifikt Grand Prix eller
            vill ha mer information om en förare eller ett racingteam.
          </p>
        </div>
        <div className={styles.introImage}>
          <picture>
            <source
              srcSet="/f1-fans-lewis-hamilton-silverstone.webp"
              type="image/webp"
            />
            <source
              srcSet="/f1-fans-lewis-hamilton-silverstone.jp2"
              type="image/jp2"
            />
            <source
              srcSet="/f1-fans-lewis-hamilton-silverstone.jpg"
              type="image/jpeg"
            />
            <img
              src="/f1-fans-lewis-hamilton-silverstone.jpg"
              alt="Lewis Hamilton fans på Silverstone Circuit"
              title="Jublande Lewis Hamilton-fans"
            />
          </picture>
        </div>
      </section>
      <section className={styles.contents}>
        <div className={styles.content}>
          <h2 className={styles.sectionHeading}>Innehåll</h2>
          <p>
            Startsidan är indelad i flera olika sektioner och ett tryck på någon
            av ikonerna nedan tar dig ner till den valda sektionen på sidan.
            Detta för att underlätta navigeringen och göra det enkelt för dig
            att hitta just det du söker.
          </p>
          <p>
            För att samla all den bästa informationen om motorsport på en sida
            krävs det mycket innehåll. Vi kommer därför kontinuerligt fortsätta
            fylla sidan med information och undersidor. Men som alla vet – Rom
            byggdes inte på en dag.
          </p>
          <ul className={styles.contentsList}>
            <li className={styles.contentsItem}>
              <a className={styles.contentsLink} href="#f1">
                <img
                  className={styles.contentsImage}
                  alt="Formel 1 logo"
                  src="/formel1.png"
                  title="F1"
                />
                <h6 className={styles.contentsLinkText}>F1</h6>
              </a>
            </li>
            <li className={styles.contentsItem}>
              <a className={styles.contentsLink} href="#formelE">
                <img
                  className={styles.contentsImage}
                  alt="ABB Formula E logo"
                  src="/formel-e.png"
                  title="Formel E"
                />
                <h6 className={styles.contentsLinkText}>Formel E</h6>
              </a>
            </li>
            <li className={styles.contentsItem}>
              <a className={styles.contentsLink} href="#NASCAR">
                <img
                  className={styles.contentsImage}
                  alt="NASCAR logo"
                  src="/nascar.png"
                  title="NASCAR"
                />
                <h6 className={styles.contentsLinkText}>NASCAR</h6>
              </a>
            </li>
            <li className={styles.contentsItem}>
              <a className={styles.contentsLink} href="#IndyCar">
                <img
                  className={styles.contentsImage}
                  alt="NTT IndyCar Series logo"
                  src="/indycar.png"
                  title="IndyCar"
                />
                <h6 className={styles.contentsLinkText}>IndyCar</h6>
              </a>
            </li>
            <li className={styles.contentsItem}>
              <a className={styles.contentsLink} href="#WEC">
                <img
                  className={styles.contentsImage}
                  alt="WEC – Le mans logo"
                  src="/wec.png"
                  title="World Endurance Championship"
                />
                <h6 className={styles.contentsLinkText}>WEC</h6>
              </a>
            </li>
            <li className={styles.contentsItem}>
              <a className={styles.contentsLink} href="#motorsport-betting">
                <img
                  className={styles.contentsImage}
                  alt="Bettingikon med pokal"
                  src="/motorsport-betting.png"
                  title="Motorsport betting "
                />
                <h6 className={styles.contentsLinkText}>Betting</h6>
              </a>
            </li>
            <li className={styles.contentsItem}>
              <a className={styles.contentsLink} href="#motorsport-tv">
                <img
                  className={styles.contentsImage}
                  alt="Motorsport på tv ikon"
                  src="/motorsport-tv.png"
                  title="Motorsport tv"
                />
                <h6 className={styles.contentsLinkText}>Motorsport på tv</h6>
              </a>
            </li>
            <li className={styles.contentsItem}>
              <a className={styles.contentsLink} href="#motorsport-youtube">
                <img
                  className={styles.contentsImage}
                  alt="Motorsport på YouTube ikon"
                  src="/motorsport-youtube.png"
                  title="Youtubekanaler för motorsportfans"
                />
                <h6 className={styles.contentsLinkText}>
                  Motorsport på YouTube
                </h6>
              </a>
            </li>
            <li className={styles.contentsItem}>
              <a className={styles.contentsLink} href="#motorsportfans-facbook">
                <img
                  className={styles.contentsImage}
                  alt="Facebook logo"
                  src="/motorsportfans-fb-group.png"
                  title="Motorsportfans facebookgrupp"
                />
                <h6 className={styles.contentsLinkText}>
                  Motorsportfans på Facebook
                </h6>
              </a>
            </li>
            <li className={styles.contentsItem}>
              <a
                className={styles.contentsLink}
                href="#racingflaggor-betydelse"
              >
                <img
                  className={styles.contentsImage}
                  alt="Racingflaggor ikon"
                  src="/flaggor-betydelse.png"
                  title="Racingflaggors betydelse"
                />
                <h6 className={styles.contentsLinkText}>
                  Racingflaggors betydelse
                </h6>
              </a>
            </li>
          </ul>
        </div>
      </section>
      <section id="f1" className={styles.formel1}>
        <div className={styles.content}>
          <h2 className={styles.sectionHeading}>F1, Formel 1</h2>
          <div className={styles.sectionContent}>
            <p>
              Formel 1, är den högsta serien för formelbilar. Varje F1-tävling
              är ett Grand Prix (GP, stort pris) och ingår i det som kallas
              Formel 1-VM. En VM-säsong består av 21 Grand Prix där det i varje
              tävling delas ut poäng till de 10 bästa förar- och
              konstruktörerna. Normalt deltar det 20 förare från 10 olika
              konstruktörsteam i ett race. Vinnaren av ett GP får 25 poäng och
              den som kniper 10:e-platsen får 1 poäng. Den av de 10 första som
              har den snabbaste varvtiden får även ett extrapoäng för det.
              Därför kan vi i slutet av racen ibland se depåstopp från de förare
              som har ett så kallat fritt stopp. Med ett fritt stopp menar man
              att en förare har möjlighet att göra ett stopp utan att den som
              ligger bakom har tillräckligt med tid för att hinna köra om under
              tiden då man är i depån. Under stoppet sätter man på nya, fräscha
              däck för att sen ge sig ut på jakt efter den bästa varvtiden. När
              sista racet avgörs i november är det den förare och som har flest
              poäng som har vunnit Formel 1-VM.
            </p>
            <div className={styles.sectionContentImg}>
              <picture>
                <source srcSet="/formel1-bil.webp" type="image/webp" />
                <source srcSet="/formel1-bil.png" type="image/png" />
                <img
                  src="/formel1-bil.png"
                  title="Valtteri Bottas F1-bil"
                  alt="Mercedes Formel 1-bil"
                />
              </picture>
            </div>
          </div>
        </div>
      </section>
      <section id="formelE" className={styles.formele}>
        <div className={styles.content}>
          <h2 className={styles.borderHeading}>Formel E</h2>
          <div className={styles.sectionContent}>
            <p>
              Formel E, som är en relativt ny serie har utvecklats ordentligt de
              senaste åren. ABB FIA Formula E Championship är den högsta
              racingserien för eldrivna formelbilar. Serien består av 22 förare
              fördelat på 11 racingteam. Mästerskapet körs på 14 olika
              stadsbanor i elbilar som accelererar från 0-100km/h på 2.8
              sekunder och har en toppfart på ca 280km/h. Poängsystemet är
              detsamma som i F1 med undantaget att den som startar på
              förstaposition, Julius Baer Pole Position, även får 3 extrapoäng.
              När säsongen summeras är vinnaren av förarmästerskapet den som har
              lyckats skramla ihop flest poäng under säsongen. Vinnaren i
              Konstruktörs-VM blir den med den som har flest poäng när man
              summerat båda förarnas totala poäng.
            </p>
            <div className={styles.sectionContentImg}>
              <picture>
                <source srcSet="/formel-e-bil.webp" type="image/webp" />
                <source srcSet="/formel-e-bil.png" type="image/png" />
                <img
                  src="/formel-e-bil.png"
                  alt="Formel E-bil"
                  title="Elektrisk formelbil"
                />
              </picture>
            </div>
          </div>
        </div>
      </section>
      <section id="NASCAR" className={styles.nascar}>
        <div className={styles.content}>
          <h2 className={styles.borderHeading}>NASCAR</h2>
          <div className={styles.sectionContent}>
            <p>
              NASCAR är en amerikansk organisation som driver flera olika
              racingserier. Den högsta serien inom NASCAR är Monster Energy
              NASCAR Cup Series. Serien som genom åren har haft många olika namn
              startade redan år 1949. Mästerskapstiteln i NASCAR tilldelas den
              förare som har samlat på sig flest poäng genom sina placeringar i
              de olika deltävlingarna.
            </p>
            <div className={styles.sectionContentImg}>
              <picture>
                <source srcSet="/nascar-bil.webp" type="image/webp" />
                <source srcSet="/nascar-bil.png" type="image/png" />
                <img
                  src="/nascar-bil.png"
                  alt="NASCAR-bil"
                  title="NAVY NASCAR bil"
                />
              </picture>
            </div>
          </div>
        </div>
      </section>
      <section id="IndyCar" className={styles.indycar}>
        <div className={styles.content}>
          <h2 className={styles.borderHeading}>IndyCar</h2>
          <div className={styles.sectionContent}>
            <p>
              IndyCar, eller NTT IndyCar Series, som det av sponsorskäl numer
              heter är en racingserie som körs mestadels på amerikanska
              racingbanor. Några av banorna, dock inte alla, körs på ovala
              banor. Bilarna som är har plats för en förare i en öppen cockpit
              är utrustade med en 2.2l twin-turbo V6:accelererar från Chevrolet
              och Honda. Likt andra racingserier inom motorsporten har serien
              ett poängsystem där den med flest poäng när serien avgörs vinner
              mästerskapet. Vår svenska racingstjärna Marcus Ericsson, som
              tidigare körde F1 för Sauber, tävlar i år med Arrow Schmidth
              Peterson Motorsports i IndyCar-serien.
            </p>
            <div className={styles.sectionContentImg}>
              <picture>
                <source srcSet="/indycar-bil.webp" type="image/webp" />
                <source srcSet="/indycar-bil.png" type="image/png" />
                <img
                  src="/indycar-bil.png"
                  alt="IndyCar-bil"
                  title="IndyCar racing bil i full fart"
                />
              </picture>
            </div>
          </div>
        </div>
      </section>
      <section id="WEC" className={styles.wec}>
        <div className={styles.content}>
          <h2 className={styles.borderHeading}>
            WEC, World Endurance Championship
          </h2>
          <p>
            WEC säsongen 2019/20 inleds med ett fyratimmarsrace i Silverstone i
            september och avslutas med 24-timmarstävlingen i Le Mans i juni
            2020. Le Mans 24-timmars, som är den största tävlingen, körs på den
            13 kilometer långa Circuit de la Sarthe i Frankrike. Under
            24-timmarsracet turas tre förare om att på 24h köra så många varv
            som möjligt runt banan. De senaste åren har man haft en
            snitthastighet runt 220km/h och har då hunnit en bit över 5000 km
            (500 mil) totalt.
          </p>
        </div>
      </section>
      <section id="motorsportfans-facbook" className={styles.fbgroup}>
        <div className={styles.content}>
          <h2 className={styles.sectionHeading}>
            Motorsportfans Facebookgrupp
          </h2>
          <p>
            I vår grupp på Facebook delar vi våra tankar om allt som hör
            motorsporten till. Analyser och tankar kring förare, team och
            strategier. Alla motorsportfans är välkomna att delta i
            diskussionen!
          </p>
          <a
            className={styles.fblink}
            rel="nofollow"
            href="https://www.facebook.com/groups/motorsportfans.se/"
          >
            <img
              alt="Facebook logo - motorsportfans grupp"
              src="/motorsportfans-fb-group.png"
            />
            Till Facebookgruppen
          </a>
        </div>
      </section>
      <section className={styles.motortv} id="motorsport-tv">
        <div className={styles.content}>
          <h2 className={styles.sectionHeading}>Motorsport på tv</h2>
          <p className={styles.preamble}>
            Trots det faktum att racing upplevs bäst på plats finns det sällan
            möjlighet att vara på plats för att se all racing live. Därför är
            det toppen att det går att streama F1, NASCAR med flera live. Om
            bristen på tid gör att du inte kan se racing live är det dessutom
            möjligt att titta på de olika racen i efterhand. Nedan har vi listat
            några olika race och via vilka operatörer du kan se dem.
          </p>
          <ul className={styles.motortvList}>
            <li className={styles.motortvItem}>
              <div className={styles.motortvItemHeader}>
                <h3 className={styles.bodyHeading}>Streama F1 på Viasat</h3>
                <img
                  src="/viaplay-logo.png"
                  className={styles.channelLogo}
                  alt="Viaplay logo"
                  title="Viaplay visar F1"
                />
              </div>
              <p>
                Formel 1 VM 2020 inleds i mars och avslutas i november. Under
                den här tiden är det 21 Grand Prix som ska avgöras innan vi i
                november vet vem som har vunnit förar- respektive
                konstruktörs-VM. För att streama free practice, kval och race
                säsongen 2020 från Sverige är det Viasat som gäller, antingen
                via Viasat Motor eller Viaplay – Ett måste för alla
                motorsportfans.
              </p>
            </li>
            <li className={styles.motortvItem}>
              <div className={styles.motortvItemHeader}>
                <h3 className={styles.bodyHeading}>
                  Formel E visas på tv från november
                </h3>
                <span className={styles.imgPlate}>
                  <img
                    src="/eurosport-logo.png"
                    className={styles.channelLogo}
                    alt="Eurosport logo"
                    title="Se Formel E på Eurosport"
                  />
                </span>
              </div>
              <p>
                ABB Formula E, är den högsta racingserien för eldrivna
                formelbilar. Formel E-säsongen 2019/20 inleds med Ad Diriyah
                E-Prix i Saudiarabien den 22 november 2019 och avslutas med
                London E-prix den 26 juli 2020. Säsongen 2018/19 visades på
                Eurosport.
              </p>
            </li>
            <li className={styles.motortvItem}>
              <div className={styles.motortvItemHeader}>
                <h3 className={styles.bodyHeading}>NASCAR visas på Viasat</h3>
                <img
                  src="/viaplay-logo.png"
                  className={styles.channelLogo}
                  alt="Viaplay logo"
                  title="Streama NASCAR på Viaplay"
                />
              </div>
              <p>
                NASCAR driver flera racingserier. De tre största serierna
                Monster Energy NASCAR Cup Series, Xfinity Series och NASCAR
                Gander Outdoors Truck Series inleds alla i februari och avslutas
                med playoff från september till november. NASCAR streamar du på
                Viaplay eller Viasat Motor.
              </p>
            </li>
            <li className={styles.motortvItem}>
              <h3 className={styles.bodyHeading}>
                Marcus Ericsson och IndyCar streamar du på Viaplay
              </h3>
              <p>
                För dig som gillar formelbilar ochj vill följa vår Svenska
                racingstjärna Marcus Ericsson och hans framfart i den
                amerikanska racingserien NTT IndyCar Series är det också Viaplay
                som gäller. Säsongen 2020 består av 17 lopp och inleds i mars
                och avslutas i september.
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section id="motorsport-youtube" className={styles.youtube}>
        <div className={styles.content}>
          <h2 className={styles.sectionHeading}>
            Titta på motorsport på YouTube
          </h2>
          <ul className={styles.ytList}>
            <li className={styles.ytItem}>
              <a
                className={styles.ytlink}
                rel="nofollow"
                href="https://www.youtube.com/user/Formula1/videos"
              >
                <div className={styles.ytInfo}>
                  <div className={styles.ytText}>
                    <h3 className={styles.ytHeading}>F1 officiell kanal</h3>
                    <p>
                      På Formel 1:s officiella youtubekanal kan du se
                      presskonferenser, intervjuer och höjdpunkter från kval och
                      tävlingar.
                    </p>
                  </div>
                  <picture>
                    <source srcSet="/f1-youtube.webp" type="image/webp" />
                    <source srcSet="/f1-youtube.jp2" type="image/jp2" />
                    <source srcSet="/f1-youtube.jpg" type="image/jpeg" />
                    <img
                      className={styles.ytImg}
                      src="/f1-youtube.jpg"
                      alt="F1 racing startposition"
                      title="F1 officiell youtubekanal"
                    />
                  </picture>
                </div>
              </a>
            </li>
            <li className={styles.ytItem}>
              <a
                rel="nofollow"
                className={styles.ytlink}
                href="https://www.youtube.com/user/nicorosbergtv/videos"
              >
                <div className={styles.ytInfo}>
                  <div className={styles.ytText}>
                    <h3 className={styles.ytHeading}>Nico Rosberg</h3>
                    <p>
                      Den före detta VM-mästaren Nico Rosberg har en
                      youtubekanal där han på engelska lägger ut analyser och
                      intervjuer inför och under f1-helgerna. Många intressanta
                      länkar och helt klart värt ett klick på subscribeknappen.
                    </p>
                  </div>
                  <picture>
                    <source
                      srcSet="/nico-rosberg-youtube.webp"
                      type="image/webp"
                    />
                    <source
                      srcSet="/nico-rosberg-youtube.jp2"
                      type="image/jp2"
                    />
                    <source
                      srcSet="/nico-rosberg-youtube.jpg"
                      type="image/jpeg"
                    />
                    <img
                      className={styles.ytImg}
                      src="nico-rosberg-youtube.jpg"
                      alt="Nico Rosberg framför F1-bilar"
                      title="Nico Rosbergs youtubekanal"
                    />
                  </picture>
                </div>
              </a>
            </li>
            <li className={styles.ytItem}>
              <a
                rel="nofollow"
                className={styles.ytlink}
                href="https://www.youtube.com/user/FIAFormulaE/videos"
              >
                <div className={styles.ytInfo}>
                  <div className={styles.ytText}>
                    <h3 className={styles.ytHeading}>Formel E</h3>
                    <p>
                      Formel E officella youtube delar referat, highlights,
                      intervjuer och klipp från träningar, kval och race. Ett
                      måste för dig som är intresserad av elbilsracing.
                    </p>
                  </div>
                  <picture>
                    <source srcSet="/formel-e-youtube.webp" type="image/webp" />
                    <source srcSet="/formel-e-youtube.jp2" type="image/jp2" />
                    <source srcSet="/formel-e-youtube.jpg" type="image/jpeg" />
                    <img
                      className={styles.ytImg}
                      src="formel-e-youtube.jpg"
                      alt="Elbilar från Monaco E-Prix "
                      title="Formel E officiel youtubekanal"
                    />
                  </picture>
                </div>
              </a>
            </li>
            <li className={styles.ytItem}>
              <a
                rel="nofollow"
                className={styles.ytlink}
                href="https://www.youtube.com/user/TheOfficialNASCAR/videos"
              >
                <div className={styles.ytInfo}>
                  <div className={styles.ytText}>
                    <h3 className={styles.ytHeading}>NASCAR</h3>
                    <p>
                      På den officiella NASCAR-kanalen kan du som tittare följa
                      med på ett stort utbud av intrevjuer, behind the
                      scenes-klipp tillsammans med motorsportnyheter och klipp
                      från olika race.
                    </p>
                  </div>
                  <picture>
                    <source srcSet="/nascar-youtube.webp" type="image/webp" />
                    <source srcSet="/nascar-youtube.jp2" type="image/jp2" />
                    <source srcSet="/nascar-youtube.jpg" type="image/jpeg" />
                    <img
                      className={styles.ytImg}
                      src="nascar-youtube.jpg"
                      alt="Ett led av racingbilar i ett NASCAR-race"
                      title="NASCAR youtubekanal"
                    />
                  </picture>
                </div>
              </a>
            </li>
            <li className={styles.ytItem}>
              <a
                rel="nofollow"
                className={styles.ytlink}
                href="https://www.youtube.com/user/indycars/videos"
              >
                <div className={styles.ytInfo}>
                  <div className={styles.ytText}>
                    <h3 className={styles.ytHeading}>NTT IndyCar Series</h3>
                    <p>
                      NTT IndyCar Series youtubekanal lägger ut ett par videos i
                      veckan med allt från höjdpunkter, klipp tagna ombord från
                      bilarna, till nyheter och intervjuer med IndyCarförare.
                    </p>
                  </div>
                  <picture>
                    <source srcSet="/indycar-youtube.webp" type="image/webp" />
                    <source srcSet="/indycar-youtube.jp2" type="image/jp2" />
                    <source srcSet="/indycar-youtube.jpg" type="image/jpeg" />
                    <img
                      className={styles.ytImg}
                      src="indycar-youtube.jpg"
                      alt="Snapshot från ett Indycar-race"
                      title="NTT IndyCar Series youtubekanal"
                    />
                  </picture>
                </div>
              </a>
            </li>
            <li className={styles.ytItem}>
              <a
                rel="nofollow"
                className={styles.ytlink}
                href="https://www.youtube.com/user/FIAWEC/videos"
              >
                <div className={styles.ytInfo}>
                  <div className={styles.ytText}>
                    <h3 className={styles.ytHeading}>
                      WEC, World Endurance Championship
                    </h3>
                    <p>
                      Den officiella FIA world endurance championship-kanalen.
                      Här hittar du korta summeringar och intervjuer från
                      tävlingarna. Vi har noterat att det finns fullängds
                      repriser från några av racen, bland annat det från
                      Spabanan i Beligen. Frågan är om vi någon gång får se
                      24-timmars racet från Le Mans i sin helhet på Youtube?
                    </p>
                  </div>
                  <picture>
                    <source srcSet="/wec-youtube.webp" type="image/webp" />
                    <source srcSet="/wec-youtube.jp2" type="image/jp2" />
                    <source srcSet="/wec-youtube.jpg" type="image/jpeg" />
                    <img
                      className={styles.ytImg}
                      src="wec-youtube.jpg"
                      alt="Bilar på led i ett endurance race"
                      title="FIA world endurance championship youtubekanal"
                    />
                  </picture>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </section>
      <section className={styles.flags} id="racingflaggor-betydelse">
        <div className={styles.content}>
          <h2 className={styles.sectionHeading}>Racingflaggors betydelse</h2>
          <p className={styles.preamble}>
            Har du funderat på vad de olika flaggorna som används vid racing
            betyder? Nedan visar vi upp alla flaggor tillsammans med en
            beksrivning av dess betydelse.
          </p>
          <ul className={styles.flagList}>
            <li className={styles.flagItem}>
              <div className={styles.flagIntro}>
                <picture className={styles.flagPicture}>
                  <source srcSet="/blaflagg.webp" type="image/webp" />
                  <source srcSet="/blaflagg.jp2" type="image/jp2" />
                  <source srcSet="/blaflagg.jpg" type="image/jpeg" />
                  <img
                    className={styles.flagImg}
                    src="/blaflagg.jpg"
                    title="Blåflagg"
                    alt="Blå flagga"
                  />
                </picture>
                <h3 className={styles.flagHeading}>Blåflagg</h3>
              </div>
              <p className={styles.flagText}>
                Under ett kvalrace visas blåflagg då en snabbare bil är på väg
                att köra om och föraren måste lämna fri väg för att undgå
                bestraffning. Under race kan samma flagga betyda att föraren
                håller på att bli varvad och också därför lämna fri väg för att
                slippa bestraffning.
              </p>
            </li>
            <li className={styles.flagItem}>
              <div className={styles.flagIntro}>
                <picture className={styles.flagPicture}>
                  <source srcSet="/gulflagg.webp" type="image/webp" />
                  <source srcSet="/gulflagg.jp2" type="image/jp2" />
                  <source srcSet="/gulflagg.jpg" type="image/jpeg" />
                  <img
                    className={styles.flagImg}
                    src="/gulflagg.jpg"
                    title="Gulflagg"
                    alt="Gul flagga"
                  />
                </picture>
                <h3 className={styles.flagHeading}>Gulflagg</h3>
              </div>
              <p className={styles.flagText}>
                Den gula flaggan viftas då det finns fara på eller bredvid
                banan. Omkörning är under tiden för gulflagg förbjuden. Om
                flaggan viftas tillsammans med skylten SC betyder det att det
                finns en säkerhetsbil på banan.
              </p>
            </li>
            <li className={styles.flagItem}>
              <div className={styles.flagIntro}>
                <picture className={styles.flagPicture}>
                  <source srcSet="/gronflagg.webp" type="image/webp" />
                  <source srcSet="/gronflagg.jp2" type="image/jp2" />
                  <source srcSet="/gronflagg.jpg" type="image/jpeg" />
                  <img
                    className={styles.flagImg}
                    src="/gronflagg.jpg"
                    title="Grönflagg"
                    alt="Grön flagga"
                  />
                </picture>
                <h3 className={styles.flagHeading}>Grönflagg</h3>
              </div>
              <p className={styles.flagText}>
                Grönflagg vinkas då fara är över och omkörning är tillåten igen.
              </p>
            </li>
            <li className={styles.flagItem}>
              <div className={styles.flagIntro}>
                <picture className={styles.flagPicture}>
                  <source srcSet="/malflagg.webp" type="image/webp" />
                  <source srcSet="/malflagg.jp2" type="image/jp2" />
                  <source srcSet="/malflagg.jpg" type="image/jpeg" />
                  <img
                    className={styles.flagImg}
                    src="/malflagg.jpg"
                    title="Målflagg"
                    alt="Svart/vit-rutig flagga"
                  />
                </picture>
                <h3 className={styles.flagHeading}>Målflagg</h3>
              </div>
              <p className={styles.flagText}>
                Den svart-/vitrutiga målflaggan viftas vid mållinjen när loppet
                är slut och föraren ska då åka in i depån nästkommande varv.
              </p>
            </li>
            <li className={styles.flagItem}>
              <div className={styles.flagIntro}>
                <picture className={styles.flagPicture}>
                  <source
                    srcSet="/varning-halka-flagga.webp"
                    type="image/webp"
                  />
                  <source srcSet="/varning-halka-flagga.jp2" type="image/jp2" />
                  <source
                    srcSet="/varning-halka-flagga.jpg"
                    type="image/jpeg"
                  />
                  <img
                    className={styles.flagImg}
                    src="/varning-halka-flagga.jpg"
                    title="Flagga varning för halka"
                    alt="Röd gul randing flagga"
                  />
                </picture>
                <h3 className={styles.flagHeading}>Varning för halka</h3>
              </div>
              <p className={styles.flagText}>
                Om det finns risk för halka t.ex om det finns olja på banan, då
                viftas den röd-gulrandinga flaggan.
              </p>
            </li>
            <li className={styles.flagItem}>
              <div className={styles.flagIntro}>
                <picture className={styles.flagPicture}>
                  <source srcSet="/rodflagg.webp" type="image/webp" />
                  <source srcSet="/rodflagg.jp2" type="image/jp2" />
                  <source srcSet="/rodflagg.jpg" type="image/jpeg" />
                  <img
                    className={styles.flagImg}
                    src="/rodflagg.jpg"
                    alt="Röd flagga"
                    title="Rödflagg"
                  />
                </picture>
                <h3 className={styles.flagHeading}>Rödflagg</h3>
              </div>
              <p className={styles.flagText}>
                Under ett kval betyder den röda flaggan att kvalet är avbrutet.
                Klockan stoppas och alla bilar måste köra in i depån.
              </p>
            </li>
            <li className={styles.flagItem}>
              <div className={styles.flagIntro}>
                <picture className={styles.flagPicture}>
                  <source srcSet="/svartflagg.webp" type="image/webp" />
                  <source srcSet="/svartflagg.jp2" type="image/jp2" />
                  <source srcSet="/svartflagg.jpg" type="image/jpeg" />
                  <img
                    className={styles.flagImg}
                    src="/svartflagg.jpg"
                    alt="Svart flagga"
                    title="Svartflagg"
                  />
                </picture>
                <h3 className={styles.flagHeading}>Svartflagg</h3>
              </div>
              <p className={styles.flagText}>
                Den svarta flaggan, alla förares skräck, viftas tillsammans med
                numret på en förare som har blivit diskvalificerad. Föraren vars
                nummer visas på skylten måste vara i depån inom 3 varv.
              </p>
            </li>
            <li className={styles.flagItem}>
              <div className={styles.flagIntro}>
                <picture className={styles.flagPicture}>
                  <source srcSet="/teknisk-flagg.webp" type="image/webp" />
                  <source srcSet="/teknisk-flagg.jp2" type="image/jp2" />
                  <source srcSet="/teknisk-flagg.jpg" type="image/jpeg" />
                  <img
                    className={styles.flagImg}
                    src="/teknisk-flagg.jpg"
                    alt="Svart flagga med orange prick i mitten"
                    title="Teknisk flagg"
                  />
                </picture>
                <h3 className={styles.flagHeading}>Teknisk flagg</h3>
              </div>
              <p className={styles.flagText}>
                Den svarta flaggan med en orange cirkel på visas då det
                upptäckts något fel på en bil. Flaggan visas tillsammans med en
                nummerskylt och betyder att föraren till bilen måste köra in i
                depån.
              </p>
            </li>
            <li className={styles.flagItem}>
              <div className={styles.flagIntro}>
                <picture className={styles.flagPicture}>
                  <source srcSet="/varningsflagg.webp" type="image/webp" />
                  <source srcSet="/varningsflagg.jp2" type="image/jp2" />
                  <source srcSet="/varningsflagg.jpg" type="image/jpeg" />
                  <img
                    className={styles.flagImg}
                    src="/varningsflagg.jpg"
                    alt="Svart/vit diagonal flagga"
                    title="Varningsflagg"
                  />
                </picture>
                <h3 className={styles.flagHeading}>Varningsflagg</h3>
              </div>
              <p className={styles.flagText}>
                Den svart-vita varningsflaggan visas tillsammans med en
                nummerskylt och betyder att föraren varnas för att ha uppträtt
                osportsligt på banan.
              </p>
            </li>
            <li className={styles.flagItem}>
              <div className={styles.flagIntro}>
                <picture className={styles.flagPicture}>
                  <source srcSet="/vitflagg.webp" type="image/webp" />
                  <source srcSet="/vitflagg.jp2" type="image/jp2" />
                  <source srcSet="/vitflagg.jpg" type="image/jpeg" />
                  <img
                    className={styles.flagImg}
                    src="/vitflagg.jpg"
                    alt="Vit flagga"
                    title="Vitflagg"
                  />
                </picture>
                <h3 className={styles.flagHeading}>Vitflagg</h3>
              </div>
              <p className={styles.flagText}>
                Den vita flaggan viftas om det finnns långsammare fordon på
                banan.
              </p>
            </li>
          </ul>
        </div>
      </section>
      <section id="motorsport-betting" className={styles.betting}>
        <div className={styles.content}>
          <h2 className={styles.sectionHeading}>Betting på motorsport</h2>
          <p>
            Vill du addera ytterligare spänning till ditt racingtittande finns
            det även möjlighet att vinna pengar på dina motorsportkunskaper. Det
            finns flera spelsidor som erbjuder livespel på F1, betting på NASCAR
            och Le Mans. Vi har därför skapat en separat{' '}
            <Link className={styles.bettingLink} to="/motorsport-betting">
              sida om motorsport betting
            </Link>{' '}
            för att hjälpa dig att hitta rätt spel, odds och bonusar.
          </p>
        </div>
      </section>
    </Page>
  )
}

export default Home
